.full-modal {
    .ant-modal {
        max-width: 100%;
        top: 0;
        padding-bottom: 0;
        margin: 0;
    }
    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .ant-modal-body {
        flex: 1;
        overflow-y: auto;
    }
}
